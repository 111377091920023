import React from 'react';
import * as styles from './style.module.scss';

import Bell from '../../../images/pricing-page/bell.svg';
import Badge from '../../../images/pricing-page/badge-check.svg';
import Gauge from '../../../images/pricing-page/gauge.svg';
import Graph from '../../../images/pricing-page/graph.svg';
import Headset from '../../../images/pricing-page/headset.svg';
import Hexagon from '../../../images/pricing-page/hexagon.svg';
import Key from '../../../images/pricing-page/key.svg';
import Puzzle from '../../../images/pricing-page/puzzle.svg';
import Stack from '../../../images/pricing-page/stack.svg';

export const PlanDescriptionSection: React.FunctionComponent = () => (
  <section className={`${styles.planDescriptionContainer} container`}>
    <h2>Included in every plan:</h2>
    <div className={styles.planDescriptionCards}>
      <div>
        <div className={styles.planDescriptionCardHeader}>
          <img src={Stack} alt="Stack Icon" />
          <h3>Unlimited Usage</h3>
        </div>
        <p>Run as many scans across all applications and environments, and with unlimited access for users.*</p>
      </div>
      <div>
        <div className={styles.planDescriptionCardHeader}>
          <img src={Hexagon} alt="Hexagon Icon" />
          <h3>API Support</h3>
        </div>
        <p>Complete coverage for REST, GraphQL, SOAP, and gRPC APIs.</p>
      </div>
      <div>
        <div className={styles.planDescriptionCardHeader}>
          <img src={Key} alt="Key Icon" />
          <h3>Authentication</h3>
        </div>
        <p>Utilize authentication as code to effectively and reliably scan all of your applications.</p>
      </div>
      <div>
        <div className={styles.planDescriptionCardHeader}>
          <img src={Gauge} alt="Gauge Icon" />
          <h3>Performance</h3>
        </div>
        <p>Optimized for speed, reliability, and depth of scanning across all applications.</p>
      </div>
      <div>
        <div className={styles.planDescriptionCardHeader}>
          <img src={Badge} alt="Badge Icon" />
          <h3>Scan Validation</h3>
        </div>
        <p>Validate and verify exploits discovered with our cURL based validation command.</p>
      </div>
      <div>
        <div className={styles.planDescriptionCardHeader}>
          <img src={Puzzle} alt="Puzzle Icon" />
          <h3>Automation</h3>
        </div>
        <p>Run in any development environment with our Docker and CLI tools.</p>
      </div>
      <div>
        <div className={styles.planDescriptionCardHeader}>
          <img src={Graph} alt="Chart Icon" />
          <h3>Historical Data</h3>
        </div>
        <p>Track scan data and gain insight into vulnerabilities over time and across environments.</p>
      </div>
      <div>
        <div className={styles.planDescriptionCardHeader}>
          <img src={Headset} alt="Headset Icon" />
          <h3>Support</h3>
        </div>
        <p>KaaKawesome documentation and email-based support from our Customer Success team.</p>
      </div>
      <div>
        <div className={styles.planDescriptionCardHeader}>
          <img src={Bell} alt="Bell Icon" />
          <h3>Notifications</h3>
        </div>
        <p>Utilize existing tools like Slack, Jira, and Azure DevOps Boards to speed up triage and fix.</p>
      </div>
    </div>
    <p>
      <i>
        * StackHawk differentiates between user and code contributor. A code contributor is an individual who has
        contributed to a code repository under StackHawk test in the last 90 days of commits.
      </i>
    </p>
  </section>
);
