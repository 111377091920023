import React from 'react';
import Default from '../../layouts/default';
import SEO from 'components/seo';
import { PricingPage } from 'components/_POC/Pricing';

const Pricing: React.FC = () => {
  const seo = {
    title: 'Pricing | Plans Built for Teams of All Sizes | StackHawk',
    description:
      "Get going with StackHawk's free Developer plan, or build a perfect plan for your team. Large Enterpsise? Drop us a line.",
    socialMediaThumb: {
      gatsbyImageData: {
        images: {
          sources: [
            {
              srcSet:
                'https://images.ctfassets.net/nx13ojx82pll/5SRe6WKyy7tWqMCSNnAMuv/e25343fcac60fb5e36f6479b51d1a2a4/stackhawk-product-thumbnail.png?w=300&h=160&q=90&fm=webp 300w,\nhttps://images.ctfassets.net/nx13ojx82pll/5SRe6WKyy7tWqMCSNnAMuv/e25343fcac60fb5e36f6479b51d1a2a4/stackhawk-product-thumbnail.png?w=600&h=320&q=90&fm=webp 600w,\nhttps://images.ctfassets.net/nx13ojx82pll/5SRe6WKyy7tWqMCSNnAMuv/e25343fcac60fb5e36f6479b51d1a2a4/stackhawk-product-thumbnail.png?w=1200&h=640&q=90&fm=webp 1200w',
              sizes: '(min-width: 1200px) 1200px, 100vw',
              type: 'image/webp',
            },
          ],
          fallback: {
            src: 'https://images.ctfassets.net/nx13ojx82pll/5SRe6WKyy7tWqMCSNnAMuv/e25343fcac60fb5e36f6479b51d1a2a4/stackhawk-product-thumbnail.png?w=1200&h=640&q=90&fm=png',
            srcSet:
              'https://images.ctfassets.net/nx13ojx82pll/5SRe6WKyy7tWqMCSNnAMuv/e25343fcac60fb5e36f6479b51d1a2a4/stackhawk-product-thumbnail.png?w=300&h=160&q=90&fm=png 300w,\nhttps://images.ctfassets.net/nx13ojx82pll/5SRe6WKyy7tWqMCSNnAMuv/e25343fcac60fb5e36f6479b51d1a2a4/stackhawk-product-thumbnail.png?w=600&h=320&q=90&fm=png 600w,\nhttps://images.ctfassets.net/nx13ojx82pll/5SRe6WKyy7tWqMCSNnAMuv/e25343fcac60fb5e36f6479b51d1a2a4/stackhawk-product-thumbnail.png?w=1200&h=640&q=90&fm=png 1200w',
            sizes: '(min-width: 1200px) 1200px, 100vw',
          },
        },
        layout: 'constrained',
        width: 1200,
        height: 640,
        placeholder: {
          fallback:
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAMAAABI111xAAAB71BMVEUkIjwkITskIDolIDolITsmIjwmIDsqHj0uHkI0Hkc6Hk0/HlJDH1YjJ0EjJkAjJT8kIz1DQFZiX3I9OlFDP1ZPS2BtantycIFPSWAqID8wIUY2Ik08IlNBI1lFI14hMEghLkciLEUiKUIjJT4oJD0wLEUmIT0rJEQxJk04KFY/KV1FKmRKKmkeO1IeOlAfN00gM0ohLUVaXXCMjJmAf45XVmqNjZp4d4eQkJ1VV2p6fI1iY30yMFk6MmNCM2tJM3JPMncaSl4bR1ocQ1cdQFQeO08fNksgM0kgM0gfNUogOE0hOVAlO1YrPV4zPmc7PnBEPXdNPX9VPYcWW2wZLjwgIC4jIzEiIjAbHCoZGSccHCobHiwXLDglKkRdSJoSbnwYNEElKjgvMD4mJzUhIjAiIzIgIS8jJDIeKjcRUFgjO1RlU64NgYsfNUMsLTsoKTckJTMfHy0qKzopKjglJjUjIzIoKTglJzUrKkZuXsEJkpoYMj8pKjcmKDYnKTcpKTYpKTctLUt2Z9MGoKUWMj4jIi8zMDorKzgpKjkfIC4wLTgqKzklJjQyLTk5NDsrKzkuLk1+beEEqawWNEAhIy8xPkQ1OT8xOD0zPUIeHy0qLjcuPEM3P0IsMTwlJzMgIS4yPkQ5RUcwNz8uLU2Ecepm27b1AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH6AMBERIh4KaHMAAAAIJJREFUCB0FwbFOwmAUBtDvlDtAw18ScDHGkDCSsPmkvhwsxsXBwQU6kCZqOUeCMCdJB1TDtWsAoLbYfex8JskBcAIAQD0BEgBqi5g7SZKAWgPGJEkaVHPvufdrxuG2MWWpBpNmOB//5/721//+vCzUyp7v57dx8PV6aWqDdwCAaekBRLcaWiOYXRkAAAAASUVORK5CYII=',
        },
      },
    },
    canonicalUrl: '',
    noindex: false,
    nofollow: false,
  };

  return (
    <Default>
      <SEO data={seo} />
      <PricingPage />
    </Default>
  );
};

export default Pricing;
