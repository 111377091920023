import React, { useState } from 'react';
import { PageHeading } from './PageHeading';
import * as styles from './style.module.scss';
import { ComparisonCard } from '../Card/Comparison/index';
import { PricingChart } from './PricingChart';
import { PlanDescriptionSection } from './PlanDescriptionSection';
import { DefaultModal } from '../../../foundations/Modals';
import RequestForm from '../../../components/RequestForm';
import { submitSubscriptionForm } from '../../../core/subscription';
import { API_SUBSCRIBE_ENDPOINT } from 'core/links';

import Chart from '../../../images/pricing-page/chart-up.svg';
import Gauge from '../../../images/pricing-page/gauge.svg';
import Globe from '../../../images/pricing-page/globe.svg';
import Headset from '../../../images/pricing-page/headset.svg';
import Key from '../../../images/pricing-page/key.svg';
import Lightning from '../../../images/pricing-page/lightning.svg';
import Puzzle from '../../../images/pricing-page/puzzle.svg';
import Sliders from '../../../images/pricing-page/sliders.svg';
import { Tooltip } from '../Tooltip';

export const PricingPage: React.FunctionComponent = () => {
  const [isAnnual, setIsAnnual] = useState<boolean>(true);
  const [showContactModal, toggleShowContactModal] = useState<boolean>(false);

  const proPrice = isAnnual ? '$42' : '$49';
  const enterprisePrice = isAnnual ? '$59' : '$69';

  return (
    <section id={styles.pricingPage}>
      <PageHeading
        headingWithoutGradient="Application Security at the "
        headingWithGradient="Speed of Software Delivery"
        subHeading="Give us a try with a free 14 day Enterprise trial."
        button1Text="Start Free Trial"
        button2Text="Compare Features"
        toggleShowContactModal={toggleShowContactModal}
      />
      <PricingChart isAnnual={isAnnual} setIsAnnual={setIsAnnual} toggleShowContactModal={toggleShowContactModal} />
      <section id={styles.princingPholosopyContainer}>
        <p>
          Today’s applications run on hundreds, if not thousands, of APIs and services and that's why we support and
          encourage testing all of your Apps and APIs as frequently as possible.
        </p>
        <p>
          We provide pricing that is easily predictable; based on your engineers who contribute code in the applications
          and APIs being tested.
        </p>
      </section>
      <PlanDescriptionSection />
      <section id={styles.pricingAbridgedContainer}>
        <div>
          <div>
            <h3 className="mb-16">Pro</h3>
            <span className="mb-4 h1 font-weight-bold d-block">{proPrice}</span>
            <a href="https://auth.stackhawk.com/signup">
              <button className={styles.btnSecondary}>Start Free Trial</button>
            </a>
          </div>
          <div className={styles.pricingAbridgedSwitcher}>
            <span className="d-flex h6 font-weight-bolder align-items-center">
              Per Code Contributor Per Month{' '}
              <Tooltip
                lineHeight="11px"
                fontSize="9px"
                fontColor="var(--color-gray)"
                borderColor="var(--color-gray)"
                borderWidth="2px"
                size="15px"
                text="?"
                hoverText="A code contributor is an individual who has contributed to a code repository under StackHawk test in the last 90 days of commits."
              />
            </span>
            <div className="mt-1 d-block font-weight-normal">
              <i>Code Contributor Minimums Apply</i>
            </div>
          </div>
          <div id="comparison">
            <h3>Enterprise</h3>
            <span className="mb-4 h1 font-weight-bold d-block">{enterprisePrice}</span>
            <a href="https://auth.stackhawk.com/signup">
              <button className={styles.btnPrimary}>Start Free Trial</button>
            </a>
          </div>
        </div>
      </section>
      <section id={`${styles.comparison}`} className="container">
        <h2>Feature Comparison</h2>
        <p className="h4 mb-5 font-weight-normal">Decide which level best fits your team.</p>
        <ComparisonCard
          imgSrc={Chart}
          title="Usage"
          list1Items={['Unlimited scans and environments', 'Unlimited applications']}
        />
        <ComparisonCard
          imgSrc={Gauge}
          title="Speed"
          subHeading="Custom scan discovery eliminates spider delays and the local scanner running next to your app removes latency wait times."
          list1Items={[
            'Custom Scan Discovery enables you to use existing Postman Collections, Cypress, Selenium or any other test scripts to seed your scan and test data.',
            "Tech flags mean you only scan for tech that's relevant to your API and app and reduce false positives",
            'Rescan lets you quickly rerun only previously failed tests to quickly validate fixes',
          ]}
          list2Items={['Policy management lets you control the tests you run']}
          proPrice={proPrice}
          enterprisePrice={enterprisePrice}
        />
        <ComparisonCard
          imgSrc={Globe}
          title="Coverage"
          subHeading="Complete coverage across all REST, GraphQL, gRPC & SOAP APIs. Custom Scan Discovery, or Custom Test Data with Open API Specs, Seed Paths and spider capabilities, enable full discovery across all paths. CustomEasily reproduce alerted vulnerabilities with cURL based validation."
          list1Items={[
            'Custom Test Data ensures all paths are access and all test cases are exercises',
            'Custom Scan Discovery enables you to use existing Postman Collections, Cypress, Selenium or any other test scripts to seed your scan and test data.',
            'Rescan lets you quickly rerun only previously failed tests to quickly validate fixes',
          ]}
          list2Items={[
            'Seed Paths mean all critical paths are scanned',
            'Policy management allows you to pull in even Alpha ZAP tests for your custom scan coverage',
            'Custom Test Scripts mean even your business logic and tenancy checks can be automated',
          ]}
          proPrice={proPrice}
          enterprisePrice={enterprisePrice}
        />
        <ComparisonCard
          imgSrc={Key}
          title="Authentication"
          list1Items={[
            'Reliable, repeatable authentication as code. Validated at the beginning of scans, saving time and frustration!',
          ]}
        />
        <ComparisonCard
          imgSrc={Lightning}
          title="Automation"
          list1Items={[
            'Runs anywhere you need it! Ephemeral Docker container integrates with all major CI/CD pipelines. Configure it to break the build on critical issues or catch issues before they hit your pipeline by enabling devs with the CLI version on their local machine.',
          ]}
        />
        <ComparisonCard
          imgSrc={Sliders}
          title="Management"
          subHeading="Manage findings across the organization with granular access control between roles for faster fixes."
          list1Items={['Applications Dashboard with historical trend visualization', 'Findings triage']}
          list2Items={[
            'Teams Based Access',
            'Role Based Permission',
            'Executive Summary Report',
            'Activity History & Audit Log',
          ]}
          proPrice={proPrice}
          enterprisePrice={enterprisePrice}
        />
        <ComparisonCard
          imgSrc={Puzzle}
          title="Interoperability"
          subHeading="Flexibility to integrate and operate with the most commonly used Dev tools."
          list1Items={[
            'Snyk & GitHub SAST integrations',
            'Add to PR checks in GitHub',
            'See scan results in GitHub PR comments',
            'Jira Cloud & Security in Jira integration',
            'Slack integration',
            'API access for all major functionality, scan results and audit logs',
          ]}
          list2Items={['AzureBoards, Defect Dojo', 'Webhooks', 'MS Teams', 'Single Sign-On']}
          proPrice={proPrice}
          enterprisePrice={enterprisePrice}
        />
        <ComparisonCard
          imgSrc={Headset}
          title="Support"
          subHeading="Customer approved support team, we're serious, our customers love our support team."
          list1Items={['Email, Chat & Slack Based Support']}
          list2Items={['Dedicated Slack-based Support', 'Premier Zoom Support']}
          proPrice={proPrice}
          enterprisePrice={enterprisePrice}
        />
        <DefaultModal
          size="lg"
          className={'request-demo-modal'}
          showButtons={false}
          show={showContactModal}
          showModal={toggleShowContactModal}
          content={
            <RequestForm
              list={'custom_pricing'}
              headline={'Pricing Contact'}
              buttonText={`Request Pricing`}
              subHeadline={
                'Looking to Chat about how StackHawk can help your team? Curious about an Enterprise rollout? Contact Us.\n'
              }
              onSubmit={submitSubscriptionForm(API_SUBSCRIBE_ENDPOINT)}
              successSubHeadline={`Request Inbound!`}
              successContent={`One of our awesome teammates will reach out to discuss your needs. It would be very helpful if you would complete this 3 minute survey so we can help customize a package that is specific to you.`}
              successBtnText={`Complete the pricing survey`}
              successBtnUrl={`https://stackhawk.typeform.com/to/l8Ef3Ku8`}
            />
          }
        />
      </section>
    </section>
  );
};
