import React from 'react';
import { FunctionComponent } from 'react';
import Pill from '../../Pill';
import * as styles from './style.module.scss';

interface ComparisonCardProps {
  imgSrc?: string;
  title: string;
  subHeading?: string;
  list1Items: string[];
  list2Items?: string[];
  proPrice?: string;
  enterprisePrice?: string;
}

export const ComparisonCard: FunctionComponent<ComparisonCardProps> = ({
  imgSrc,
  title,
  subHeading,
  list1Items,
  list2Items,
  proPrice,
  enterprisePrice,
}) => {
  const hasTwoColumns = !!list2Items && list2Items.length > 0;

  return (
    <div className={styles.comparisonCardContainer}>
      <div className={styles.comparisonCardHeader}>
        <img src={imgSrc} alt={`${title} logo`} />
        <h3>{title}</h3>
      </div>
      {!hasTwoColumns && (
        <p>
          Available on both our <span>PRO</span> and <span>ENTERPRISE</span> plans:
        </p>
      )}
      {<p>{subHeading}</p>}
      <div className={`${hasTwoColumns && styles.halfSize}`}>
        <div>
          {hasTwoColumns && <Pill type="ComparisonPro" color="rgb(148, 106, 242)" text="PRO" pricingText={proPrice} />}
          <ul>
            {list1Items.map((item, idx) => (
              <li key={idx} className={`${!!list2Items && styles.list1Text}`}>
                {item}
              </li>
            ))}
          </ul>
        </div>
        {hasTwoColumns && (
          <div>
            {hasTwoColumns && (
              <Pill
                type="ComparisonEnterprise"
                color="rgb(0, 203, 198)"
                text="ENTERPRISE"
                pricingText={enterprisePrice}
              />
            )}
            <p>Everything in Pro, plus:</p>
            <ul>
              {list2Items?.map((item, idx) => {
                return (
                  <li key={idx} className={styles.list2Text}>
                    {item}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};
