import React from 'react';
import './style.scss';

interface SwitcherProps {
  isAnnual: boolean;
  id: string;
  setIsAnnual: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Switcher: React.FunctionComponent<SwitcherProps> = ({ isAnnual, id, setIsAnnual }) => {
  return (
    <div className="btn-container">
      <label className="switch btn-color-mode-switch">
        <input onClick={() => setIsAnnual(!isAnnual)} type="checkbox" name={id} id={id} checked={isAnnual} />
        <label htmlFor={id} data-on="Annually" data-off="Monthly" className="btn-color-mode-switch-inner"></label>
      </label>
      <i className="fa fa-moon-o" aria-hidden="true"></i>
    </div>
  );
};
