import React from 'react';
import { PricingCard } from '../Card/Pricing';
import * as styles from './style.module.scss';

interface PricingChartProps {
  isAnnual: boolean;
  setIsAnnual: React.Dispatch<React.SetStateAction<boolean>>;
  toggleShowContactModal: (value: boolean) => void;
}

export const PricingChart: React.FunctionComponent<PricingChartProps> = ({
  isAnnual,
  setIsAnnual,
  toggleShowContactModal,
}) => (
  <section id={styles.pricingSection} className="">
    <div className={styles.mainPricingCards}>
      <PricingCard
        title="Pro"
        price={isAnnual ? '$42' : '$49'}
        variant="proCard"
        minContrib="Five"
        description="Pro offers teams the ability to find, triage, and fix security issues within the developer ecosystem across all applications and APIs."
        devText="Per Code Contributor Per Month"
        buttonText="Talk to an Expert"
        buttonLink="/contact"
        toggleShowContactModal={toggleShowContactModal}
        hasSwitch={true}
        savingsText=""
      />
      <PricingCard
        title="Enterprise"
        price={isAnnual ? '$59' : '$69'}
        variant="enterpriseCard"
        minContrib="20"
        description="Enterprise offers all the advantages of our Pro plan, and includes customized scanning with expanded coverage to manage vulnerabilities across organizations and teams."
        devText="Per Code Contributor Per Month"
        buttonText="Talk to an Expert"
        buttonLink="/contact"
        toggleShowContactModal={toggleShowContactModal}
        hasSwitch={true}
        savingsText=""
      />
      <PricingCard
        title="Custom"
        price="Contact our AppSec Professionals"
        variant="customCard"
        description="Have more than 50 developers? Volume discounts are available. Contact our team to discuss your AppSec needs!"
        buttonText="Contact Us"
        buttonLink="/contact"
        toggleShowContactModal={toggleShowContactModal}
      />
    </div>
  </section>
);
