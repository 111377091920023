import React from 'react';
import * as styles from './style.module.scss';
import { FunctionComponent } from 'react';
interface PageHeadingProps {
  leadText?: string;
  headingWithoutGradient: string;
  headingWithGradient?: string;
  subHeading: string;
  button1Text: string;
  button2Text: string;
  toggleShowContactModal: (value: boolean) => void;
}

export const PageHeading: FunctionComponent<PageHeadingProps> = ({
  leadText,
  headingWithGradient,
  headingWithoutGradient,
  subHeading,
  button1Text,
  button2Text,
  toggleShowContactModal,
}) => (
  <div className={styles.pageHeadingContainer}>
    <h1 style={{ fontSize: '56px' }}>
      {headingWithoutGradient} <br />
      <span>{headingWithGradient}</span>
    </h1>
    <h2>{subHeading}</h2>
    <div>
      <a href="#">
        <button onClick={() => toggleShowContactModal(true)} className={styles.btnPrimary}>
          Talk to an Expert
        </button>
      </a>
      <a href="#comparison">
        <button className={styles.btnOutline}>{button2Text}</button>
      </a>
    </div>
  </div>
);
