import React from 'react';
import * as tooltipStyles from './style.module.scss';

interface TooltipProps {
  text: string;
  hoverText: string;
  size: string;
  borderWidth?: string;
  borderColor?: string;
  lineHeight?: string;
  fontSize?: string;
  fontColor?: string;
}

export const Tooltip: React.FunctionComponent<TooltipProps> = ({
  text,
  hoverText,
  size,
  borderWidth,
  borderColor,
  lineHeight,
  fontSize,
  fontColor,
}) => (
  <span
    className={tooltipStyles.tooltipContainer}
    style={{
      width: size,
      height: size,
      lineHeight: lineHeight,
      borderWidth: borderWidth,
      borderColor: borderColor,
      fontSize: fontSize,
      color: fontColor,
    }}
  >
    {text}
    <p
      style={{
        display: 'none',
      }}
    >
      {hoverText}
    </p>
    <span
      className={tooltipStyles.arrow}
      style={{
        display: 'none',
      }}
    />
    <span className={tooltipStyles.arrow} />
  </span>
);
