import React from 'react';
import * as styles from '../../Pricing/style.module.scss';
import { Switcher } from '../../Switcher';
import { FunctionComponent } from 'react';

interface PricingCardProps {
  title: string;
  variant: string;
  price: string;
  hasSwitch?: boolean;
  description: string;
  devText?: string;
  savingsText?: string;
  buttonText?: string;
  buttonLink?: string;
  isAnnual?: boolean;
  setIsAnnual?: React.Dispatch<React.SetStateAction<boolean>>;
  toggleShowContactModal?: (value: boolean) => void;
}

export const PricingCard: FunctionComponent<PricingCardProps> = ({
  title,
  variant,
  price,
  hasSwitch = false,
  description,
  devText,
  savingsText,
  minContrib = 'Five',
  buttonText = 'Start Free Trial',
  buttonLink = 'https://auth.stackhawk.com/signup',
  isAnnual,
  setIsAnnual,
  toggleShowContactModal,
}) => {
  const determineButtonStyle = () => {
    if (variant === 'freeCard') {
      return styles.btnOutline;
    } else if (variant === 'proCard') {
      return styles.btnSecondary;
    } else if (variant === 'enterpriseCard') {
      return styles.btnPrimary;
    } else if (variant === 'customCard') {
      return styles.btnAsh;
    }
  };

  /*TODO: Remove A tags around buttons*/
  return (
    <div className={`${styles.pricingCardContainer} ${styles[variant]}`}>
      <div className={styles.pricingCardContent}>
        <h3 className={'mb-3'}>{title}</h3>
        <span
          className={`${
            variant === 'customCard' ? 'h5 font-weight-bolder mb-lg-6' : 'mb-4 h1 font-weight-bold'
          } d-block`}
        >
          {price}
        </span>
        {!!devText && (
          <>
            <h5>{devText}</h5>
            <h6>
              <i>{minContrib} Contributor Minimum</i>
            </h6>
          </>
        )}
        {hasSwitch && isAnnual !== undefined && !!setIsAnnual && (
          <Switcher isAnnual={isAnnual} setIsAnnual={setIsAnnual} id={`${variant}Switcher`} />
        )}
        <p>{description}</p>
        {!!savingsText && (
          <h2>
            Save <span className={'fw-800'}>{savingsText}</span> Annually
          </h2>
        )}
        <a href={toggleShowContactModal ? '#' : buttonLink}>
          {toggleShowContactModal ? (
            <button className={determineButtonStyle()} onClick={() => toggleShowContactModal(true)}>
              {buttonText}
            </button>
          ) : (
            <button className={`mb-0 ${determineButtonStyle()}`}>{buttonText}</button>
          )}
        </a>
      </div>
    </div>
  );
};
